import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

export const HomePageTemplate = ({ image, text }) => (
  <Layout style={{ textAlign: 'center' }}>
    <img
      css={{ width: 650, maxWidth: '100%', borderRadius: 7 }}
      src={image}
      alt="De bewoners van Heerenhuis Double-U-Seven"
    />
    <p css={{ whiteSpace: 'pre-line' }}>{text}</p>
  </Layout>
)

export default ({ data }) => {
  const { image, text } = data.pagesYaml
  return <HomePageTemplate image={image} text={text} />
}

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    pagesYaml(id: { eq: $id }) {
      image
      text
    }
  }
`
